var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("CSelect", {
    attrs: {
      label: _vm.label,
      placeholder: _vm.placeholder,
      value: _vm.valueSync,
      options: _vm.options,
    },
    on: {
      "update:value": function ($event) {
        _vm.valueSync = $event
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }